import * as React from "react";
import { useEffect } from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import PlainHeader from "components/ui/base/PlainHeader";
import Customers from "components/ui/extended/Customers";
import { ChatWithUs } from "components/ui/extended/ChatWithUs";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Testimonials from "components/ui/extended/Testimonials";
import CustomerSatisfactionStories from "components/ui/extended/CustomerSatisfactionStories";
import MarketingAutomationStories from "components/ui/extended/MarketingAutomationStories";
import CustomerServiceStories from "components/ui/extended/CustomerServiceStories";
import Steps from "components/ui/base/Steps";
import { APP_URL } from "constants/paths";
import Cookies from "universal-cookie";
import { AUTHENTICATION_COOKIE_NAME } from "constants/cookies";

interface IndexPageProps {
  location: Location;
}

export default ({ location }: IndexPageProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    const cookies = new Cookies(document.cookie);

    if (cookies.get(AUTHENTICATION_COOKIE_NAME) !== undefined) {
      window.location.replace(APP_URL);
    }
  }, []);

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.index.seo.title")}
        description={t("pages.features.index.seo.description")}
        withoutTitleSuffix={true}
        image={{
          relativePath:
            "meta/customerly-customer-centric-communication-platform.jpg",
          alt: "Customer Centric communication platform ",
        }}
      />

      <PlainHeader
        title={t("pages.index.title")}
        titleMaxWidth={800}
        descriptionMaxWidth={700}
        description={t("pages.index.description")}
        ctaRegister={{ show: true }}
        image={{
          relativePath:
            "pages/index/customer-service-software-with-live-chat.jpg",
          alt: "Customerly",
        }}
        news={{
          text: t("pages.index.news"),
          relativePath: "/features/live-chat/",
        }}
      />

      <Customers />

      <Steps
        title={t("pages.index.steps.title")}
        steps={[
          {
            title: t("pages.index.steps.step1.title"),
            description: t("pages.index.steps.step1.paragraph"),
            image: {
              relativePath: "icons/live-chat.png",
              alt: "Live Chat icon",
            },
          },
          {
            title: t("pages.index.steps.step2.title"),
            description: t("pages.index.steps.step2.paragraph"),
            image: {
              relativePath: "icons/automation.png",
              alt: "Marketing Automation icon",
            },
          },
          {
            title: t("pages.index.steps.step3.title"),
            description: t("pages.index.steps.step3.paragraph"),
            image: {
              relativePath: "icons/nps.png",
              alt: "NPS Survey icon",
            },
          },
        ]}
      />

      <CustomerServiceStories />

      <MarketingAutomationStories />

      <CustomerSatisfactionStories />

      <Testimonials />

      <RegisterForCustomerService />

      <ChatWithUs />
    </GlobalWrapper>
  );
};
